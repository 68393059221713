import {useCallback, useEffect, useRef} from 'react';

export default function useTimeout() {
  const timeout = useRef(null);
  const set = useCallback((callback, delay) => {
    timeout.current = setTimeout(callback, delay);
  }, []);
  const clear = useCallback(() => clearTimeout(timeout.current), []);
  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);
  return [set, clear];
}
